<template>
  <v-app>
    <core-snackbar />

    <v-progress-linear
      :active="loading"
      indeterminate
      absolute
      top
      color="deep-purple accent-4"
    />
    <v-container
      fluid
      fill-height
      class="lighten-5"
      :class="data.typeOfApportionment.color"
    >
      <v-row>
        <v-col
          cols="12"
          class="py-0"
        >
          <v-row
            no-gutters
            justify="center"
          >
            <v-col
              class="no-gutters"
              cols="12"
              md="4"
            >
              <v-img
                :src="
                  require('@/assets/images/logos/search.png')
                "
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="12"
          class="py-0"
        >
          <v-row
            no-gutters
            justify="center"
          >
            <v-col
              cols="12"
              md="5"
            >
              <v-row>
                <v-col class="text-center">
                  <v-btn-toggle
                    v-model="data.typeOfApportionment"
                    shaped
                    mandatory
                    borderless
                    @change="apportionments = null"
                  >
                    <v-btn
                      v-for="(type) in typesOfApportionments"
                      :key="type.id"
                      :value="type"
                      :color="type.color"
                      dark
                      outlined
                    >
                      <span class="font-weight-black">{{ type.description }}</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <validation-observer ref="observer">
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      vid="month"
                    >
                      <v-select
                        v-model="data.month"
                        :items="months"
                        item-value="id"
                        item-text="description"
                        label="Mês"
                        solo
                        hide-details="auto"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      vid="year"
                    >
                      <v-select
                        v-model="data.year"
                        :items="years"
                        item-value="id"
                        item-text="description"
                        label="Ano"
                        solo
                        hide-details="auto"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      vid="cityId"
                    >
                      <v-autocomplete
                        v-model="data.cityId"
                        :items="cities"
                        item-value="id"
                        item-text="name"
                        label="Prefeitura"
                        solo
                        hide-details="auto"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </validation-observer>

              <v-row>
                <v-col>
                  <v-btn
                    color="primary"
                    :disabled="loading"
                    @click="searchApportionments"
                  >
                    <v-icon
                      left
                    >
                      mdi-magnify
                    </v-icon>
                    Pesquisar
                  </v-btn>
                </v-col>
              </v-row>

              <v-row
                v-if="apportionments !== null"
                class="mb-4"
              >
                <v-col>
                  <h2 class="display-5">
                    Repasses
                  </h2>
                  <v-sheet
                    elevation="2"
                  >
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Dia/Mễs
                            </th>
                            <th class="text-left">
                              Valor Estimado
                            </th>
                            <th class="text-left">
                              Valor Realizado
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-if="apportionments.length <=0 "
                          >
                            <td
                              colspan="3"
                              class="title"
                            >
                              <i>Nenhum repasse encontrado</i>
                            </td>
                          </tr>
                          <tr
                            v-for="(apportionment, index) in apportionments"
                            :key="index"
                          >
                            <td>{{ apportionment.date | date }}</td>
                            <td>{{ apportionment.estimatedValue | currency }}</td>
                            <td>{{ apportionment.realizedValue | currency }}</td>
                          </tr>

                          <tr class="grey lighten-2">
                            <td class="font-weight-bold">Total</td>
                            <td class="font-weight-bold">{{ estimatedValueTotal | currency }}</td>
                            <td class="font-weight-bold">{{ realizedValueTotal | currency }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!-- <v-footer
      padless
      :class="data.typeOfApportionment.color"
      class="lighten-4"
    >
      <v-col
        class="text-center"
        cols="12"
      >
        Ficou com alguma dúvida? Entre em contato pelo WhatsApp:
        <strong>
          <a
            href="https://wa.me/5551999938808"
            class="text-decoration-none"
          >
            (51) 9 9993-8808
            <v-icon
              small
              right
              color="green accent-4"
              v-text="'mdi-whatsapp'"
              class="pb-1"
            />
          </a>
        </strong>
      </v-col>
    </v-footer> -->
  </v-app>
</template>

<script>
  import citiesApi from '@/api/cities'
  import typesOfApportionmentsApi from '@/api/types-of-apportionments'
  import apportionmentsApi from '@/api/apportionments'

  import date from '@/utils/date'

  export default {
    components: {
      CoreSnackbar: () => import('@/components/core/CoreSnackbar'),
    },

    data () {
      return {
        loading: false,
        months: date.months(),
        years: date.years(),
        cities: [],
        typesOfApportionments: [],
        apportionments: null,
        data: {
          typeOfApportionment: {},
          month: (new Date()).getMonth() + 1,
          year: (new Date()).getFullYear(),
          cityId: null,
        },
      }
    },

    computed: {
      estimatedValueTotal () {
        return this.apportionments.reduce(
          (accumulator, currentValue) => accumulator + currentValue.estimatedValue, 0
        )
      },
      realizedValueTotal () {
        return this.apportionments.reduce(
          (accumulator, currentValue) => accumulator + currentValue.realizedValue, 0
        )
      }
    },

    mounted () {
      this.loadCities()
      this.loadTypesOfApportionments()
    },

    methods: {
      async loadCities () {
        try {
          this.loading = true

          const response = await citiesApi.list({
            limit: 1000,
            offset: 0,
          })

          this.cities = response.data.cities
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async loadTypesOfApportionments () {
        try {
          this.loading = true

          const response = await typesOfApportionmentsApi.list()

          this.typesOfApportionments = response.data.typesOfApportionments
          this.data.typeOfApportionment = this.typesOfApportionments[0]
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      },

      async searchApportionments () {
        try {
          this.loading = true
          this.apportionments = null

          this.$refs.observer.reset()

          const filters = { ...this.data }
          filters.typeOfApportionmentId = filters.typeOfApportionment.id

          delete filters.typeOfApportionment

          const response = await apportionmentsApi.search(filters)

          this.apportionments = response.data.apportionments
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

    },

  }
</script>
